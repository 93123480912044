import React, { useMemo, useState } from "react";
import FAQsData from "./faqAsk.json";
import clsx from "clsx";
import { Minus, Plus } from "@styled-icons/boxicons-regular";
import { SectionContainer } from "../../components/common/sectionContainer";
import { Layout } from "../../components/common";

const FILTER_OPTIONS = ["All", "General", "Technical", "Pricing", "Features", "Security", "Onboarding", "Additional Resources"];

const FAQs = FAQsData?.faqs ?? [];
const FILTER_BY_ALL = "All";

export default () => {
    const [filterBy, setFilterBy] = useState<string>(FILTER_BY_ALL);
    const [activeFAQs, setActiveFAQs] = useState<Record<string, number | null>>({});

    const renderAnswerWithLinks = (answer: string): (string | JSX.Element)[] => {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
        const parts: (string | JSX.Element)[] = [];
        let lastIndex = 0;

        answer.replace(regex, (match, text, href, offset) => {
            parts.push(answer.slice(lastIndex, offset));
            parts.push(
                <a
                    key={offset}
                    href={href}
                    //   target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary hover:underline"
                >
                    {text}
                </a>
            );
            lastIndex = offset + match.length;
            return "";
        });

        answer.replace(emailRegex, (email, offset) => {
            if (offset > lastIndex) {
                parts.push(answer.slice(lastIndex, offset));
            }
            parts.push(
                <a
                    key={`email-${offset}`}
                    href={`mailto:${email}`}
                    className="text-primary hover:underline"
                >
                    {email}
                </a>
            );
            lastIndex = offset + email.length;
            return "";
        });

        parts.push(answer.slice(lastIndex));
        return parts;
    };
    const handleAccordionToggle = (index: number) => {
        setActiveFAQs((prevState) => ({
            ...prevState,
            [filterBy]: prevState[filterBy] === index ? null : index,
        }));
    };

    const filteredFAQs = useMemo(() => {
        const normalizedFilterBy = Array.isArray(filterBy)
            ? filterBy.map((item) => item.toLowerCase())
            : [filterBy.toLowerCase()];

        if (normalizedFilterBy.includes(FILTER_BY_ALL)) return FAQs;

        return (FAQs as any)?.filter(({ tags }) =>
            tags.some((tag: string) => normalizedFilterBy.includes(tag.toLowerCase()))
        );
    }, [filterBy]);
    return (
        <Layout title='FAQs' hrefLang="us" href="https://www.unizo.ai/faqs" canonical="https://www.unizo.ai/faqs">
            <div className="layout mb-5">
                <SectionContainer
                    title="Frequently Asked Questions"
                    rootClassName="!pb-2"
                />
                {/* Filter Tabs */}
                <div className="flex gap-2 mb-6 mt-24 w-[80%] m-auto">
                    {FILTER_OPTIONS.map((option) => (
                        <button
                            style={{ border: "0px" }}
                            key={option}
                            onClick={() => setFilterBy(option)}
                            className={clsx(
                                "px-4 py-2 rounded-lg",
                                filterBy === option
                                    ? "bg-primary text-white"
                                    : "bg-gray-200 text-gray-800"
                            )}
                        >
                            {option}
                        </button>
                    ))}
                </div>

                {/* FAQs */}
                <div className="flex w-[80%] flex-col gap-3 m-auto">
                    {filteredFAQs.map((faq, index) => (
                        <div>
                            <Accordion
                                key={faq.question}
                                title={faq.question}
                                open={index === activeFAQs[filterBy]}
                                onToggle={() => handleAccordionToggle(index)}
                            >
                                <p className="text-sm leading-7 text-gray-800"> {renderAnswerWithLinks(faq.answer)}</p>
                            </Accordion>
                            {index !== filteredFAQs.length - 1 && (
                                <div className="border-t my-2" style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>
                            )}
                        </ div>


                    ))}
                </div>
            </div>
        </Layout>
    );
};

function Accordion({ title, children, open, onToggle }) {

    return (
        <div className={clsx("border-b", open ? "bg-gray-100" : "")}>
            {/* Header */}
            <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={onToggle}
            >
                <h3 className="text-lg font-semibold">{title}</h3>
                {open ? <Minus className="w-5 h-5" /> : <Plus className="w-5 h-5" />}
            </div>

            {/* Content */}
            {open && <div className="p-4 pt-0">{children}</div>}
        </div>
    );
}
